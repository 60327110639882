import { default as index7MAsKdetaYMeta } from "/tmp/build_a15f1489/pages/connections/index.vue?macro=true";
import { default as indexfm6PpQpJMzMeta } from "/tmp/build_a15f1489/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_93g5NXGlBZVnMeta } from "/tmp/build_a15f1489/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_93m9kJbmb39QMeta } from "/tmp/build_a15f1489/pages/gallery/[file_id].vue?macro=true";
import { default as galleryl9cu3Sf2jwMeta } from "/tmp/build_a15f1489/pages/gallery.vue?macro=true";
import { default as indexZFl0cbpcLOMeta } from "/tmp/build_a15f1489/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_933vd7eYvh0WMeta } from "/tmp/build_a15f1489/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45in3bEkkBbaWWMeta } from "/tmp/build_a15f1489/pages/log-in.vue?macro=true";
import { default as log_45outh8XjX3b0UlMeta } from "/tmp/build_a15f1489/pages/log-out.vue?macro=true";
import { default as indexi8qGAKQ7ieMeta } from "/tmp/build_a15f1489/pages/notifications/index.vue?macro=true";
import { default as projects0paq0KgBRDMeta } from "/tmp/build_a15f1489/pages/projects.vue?macro=true";
import { default as _91file_id_93baJ450P2ySMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93VnTQW1v1wcMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexIazSyrdqhxMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visitedGHeLY9DTUjMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_93UCrJFeYsZVMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93TJdxVo1wwMMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_938EbIgDhKP1Meta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as indexvLOk5afjvHMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_93VMVEXrBaVUMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_935dih4IEYUTMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexB6sDwMsN55Meta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93mbUrl28QncMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projects5GXgBusfyEMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_93D7f5Hhvyf6Meta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93ROOmuefTMjMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexScvb5poq2UMeta } from "/tmp/build_a15f1489/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomeYYKK0UeIbtMeta } from "/tmp/build_a15f1489/pages/welcome.vue?macro=true";
import { default as component_45stubAksJHiyHp7Meta } from "/tmp/build_a15f1489/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubAksJHiyHp7 } from "/tmp/build_a15f1489/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: index7MAsKdetaYMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexfm6PpQpJMzMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_a15f1489/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryl9cu3Sf2jwMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_a15f1489/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexZFl0cbpcLOMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_a15f1489/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_a15f1489/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_a15f1489/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexi8qGAKQ7ieMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projects0paq0KgBRDMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: indexIazSyrdqhxMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visitedGHeLY9DTUjMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: indexB6sDwMsN55Meta || {},
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projects5GXgBusfyEMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexScvb5poq2UMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeYYKK0UeIbtMeta || {},
    component: () => import("/tmp/build_a15f1489/pages/welcome.vue")
  },
  {
    name: component_45stubAksJHiyHp7Meta?.name,
    path: "/",
    component: component_45stubAksJHiyHp7
  },
  {
    name: component_45stubAksJHiyHp7Meta?.name,
    path: "/library",
    component: component_45stubAksJHiyHp7
  },
  {
    name: component_45stubAksJHiyHp7Meta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stubAksJHiyHp7
  }
]